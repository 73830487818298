import { getServerSideProps } from '@/modules/products/ProductTemplateSelectorPage/productTemplateSelectorPage.props'

// View
import ProductTemplateSelectorPage from '@/modules/products/ProductTemplateSelectorPage/ProductTemplateSelectorPage';
import displayPage from '@/layout/PageLayout'

// Get Paths and Props
export { getServerSideProps }

// Render
export default displayPage(ProductTemplateSelectorPage)
